@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css");
@import url("https://www.w3schools.com/w3css/4/w3.css");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.besideEachOther {
  display: flex;
  align-items: center;
  gap: 20px;
}

.twoFontAwesomeSymbols {
  display: flex;
  align-items: center;
  gap: 5px;
}

.w3-green {
  color: #fff !important;
  background-color: #8cc23f !important;
}

.w3-blue {
  color: #fff !important;
  background-color: #4c95ce !important;
}

.w3-text-blue {
  color: #4c95ce !important;
}

.bluryText {
  color: transparent;
  text-shadow: 0 0 8px #000;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.divForDefaultColorButtons {
  display: flex;
  gap: 20px;
}

.createFolioIconsInputs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 23px;
  margin-bottom: 10px;
}

.createFolioIconsInputs .icon {
  width: 40px;
}
.createFolioIconsInputs .input {
  flex-grow: 1;
}

.createFolioSectionHeader {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 0.5px solid silver;
}

.createFolioSectionHeaderExpandExtractSign {
}

/* Beginning of skills section CSS where items are dragged and dropped */
.item {
  width: 400px;
  max-width: 80%;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 8px;
  padding: 3px 9px;
  background-color: lightcoral;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item:not(:last-child) {
  margin-bottom: 8px;
}

.mainDiv {
  padding: 30px;
}

.deleteItem {
  /* border: 1px solid black; */
  padding: 0px 13px;
  cursor: pointer;
}
.container {
  width: 800px;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: start;
  align-content: flex-start;
  gap: 10px;
  padding: 10px 3px;
  border: 1px solid black;
  resize: both;
  overflow: auto;
}

.publicContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: start;
  align-content: flex-start;
  gap: 5px;
  padding: 0px 0px;
}

.itemInContainer {
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 18px;
  padding: 5px 9px;
}

.item2 {
  /* width: 400px;
  max-width: 80%; */
  cursor: all-scroll;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 18px;
  padding: 5px 9px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 21px;
}
/* END of skills section CSS where items are dragged and dropped */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .divForDefaultColorButtons {
    display: flex;
    gap: 10px;
    flex-direction: column;
  }
}
